import React, { useState } from "react";
import API from "../services/API";
import { useHistory, useParams } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormFeedback,
  FormGroup,
} from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";

const NewPasswordPage = () => {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  });

  const { id, token } = useParams();

  const [passwordMessage, setPasswordMessage] = useState("");
  const [isNewPasswordError, setIsNewPasswordError] = useState(false);
  const [isConfirmPasswordError, setIsConfirmPasswordError] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const history = useHistory();

  async function checkEnterKey(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      await submitPasswordResetConfirmation();
    }
  }

  async function submitPasswordResetConfirmation() {
    setIsNewPasswordError(false);
    setIsConfirmPasswordError(false);
    setPasswordMessage("");

    try {
      const body = {
        uid: id,
        token: token,
        new_password: password,
        re_new_password: passwordConfirm,
      };
      const url =
        process.env.REACT_APP_API_URL + "/auth/users/reset_password_confirm/";
      let response = await API.APICallUnauthorized(url, "POST", body);
      if (response.status === 401) {
        setPasswordMessage("Authentication Error");
        setIsConfirmPasswordError(true);
        setIsNewPasswordError(true);
      } else if (response.status === 400) {
        const errorMessages = await response.json();

        if (errorMessages.hasOwnProperty("new_password")) {
          setPasswordMessage(errorMessages.new_password[0]);
          setIsNewPasswordError(true);
        } else if (errorMessages.hasOwnProperty("re_new_password")) {
          setPasswordMessage(errorMessages.re_new_password[0]);
          setIsConfirmPasswordError(true);
        } else if (errorMessages.hasOwnProperty("non_field_errors")) {
          setPasswordMessage(errorMessages.non_field_errors[0]);
          setIsConfirmPasswordError(true);
          setIsNewPasswordError(true);
        } else if (errorMessages.hasOwnProperty("token")) {
          setPasswordMessage(errorMessages.token[0]);
          setIsConfirmPasswordError(true);
          setIsNewPasswordError(true);
        }
      } else if (response.status === 204) {
        history.push("/login");
      }
    } catch (e) {
      console.log("An error occurred", e);
    }
  }

  return (
    <React.Fragment>
      <MainNavbar isBlackBg={true} />
      <div
        className="page-header"
        style={{
          backgroundImage: "url(" + "assets/img/HomePageImage.jpg" + ")",
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4">
              <Card className="card-register ml-auto mr-auto">
                <h3 className="title mx-auto">Set New Password</h3>
                <Form className="register-form">
                  <FormGroup>
                    <label>Enter new password</label>
                    <Input
                      invalid={isNewPasswordError}
                      // valid={checkValid}
                      type="password"
                      // error={usernameError}
                      // helperText={usernameMessage}
                      // required
                      onChange={(event) => {
                        setPasswordMessage("");
                        setIsNewPasswordError(false);
                        setIsConfirmPasswordError(false);
                        setPassword(event.target.value);
                      }}
                      onKeyPress={(event) => checkEnterKey(event)}
                      placeholder="New Password"
                    />
                    <Input
                      invalid={isConfirmPasswordError}
                      // valid={checkValid}
                      type="password"
                      // error={usernameError}
                      // helperText={usernameMessage}
                      // required
                      onChange={(event) => {
                        setPasswordConfirm(event.target.value);
                      }}
                      onKeyPress={(event) => checkEnterKey(event)}
                      placeholder="Confirm Password"
                    />
                    <FormFeedback tooltip>{passwordMessage}</FormFeedback>
                  </FormGroup>
                  <Button
                    block
                    className="btn-round"
                    color="default"
                    onClick={submitPasswordResetConfirmation}
                    // target="_blank"
                  >
                    SUBMIT
                  </Button>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="footer register-footer text-center">
          <h6>
            © {new Date().getFullYear()}, made by
            <Button
              style={{ padding: "5px", marginBottom: "3px" }}
              className="btn-link"
              color="danger"
              target="_blank"
              href="https://intentio.co.za"
            >
              Intentio
            </Button>
          </h6>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewPasswordPage;
